import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import Bankid from 'public/assets/logotype/bankid.svg';
import Trustly from 'public/assets/logotype/trustly.svg';
import Zaver from 'public/assets/logotype/zaver.svg';
import CarInfo from 'public/assets/logotype/car-info.svg';
import Swish from 'public/assets/logotype/swish.svg';
import AutoConcept from 'public/assets/logotype/autoconcept2.svg';
import NordeaFinance from 'public/assets/logotype/nordeafinance.svg';
import TryggHansa from 'public/assets/logotype/trygghansa.svg';

import '@splidejs/react-splide/css/core';

import styles from './PartnerSlider.module.scss';

export const PartnerSlider: React.FC = () => (
  <Splide
    options={{ arrows: false, autoWidth: true, pagination: false }}
    aria-label="Logos"
    className={styles.slider}
  >
    <SplideSlide className={styles.larger}>
      <Bankid />
    </SplideSlide>
    <SplideSlide>
      <TryggHansa />
    </SplideSlide>
    <SplideSlide className={styles.larger}>
      <NordeaFinance />
    </SplideSlide>
    <SplideSlide>
      <Trustly />
    </SplideSlide>
    <SplideSlide>
      <Zaver />
    </SplideSlide>
    <SplideSlide>
      <CarInfo />
    </SplideSlide>
    <SplideSlide>
      <Swish />
    </SplideSlide>
    <SplideSlide className={styles.larger}>
      <AutoConcept />
    </SplideSlide>
  </Splide>
);
